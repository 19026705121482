// app/frontend/entrypoints/application.js

console.log('Vite ⚡️ Rails');
console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails');

// Import Stimulus
import { Application } from "@hotwired/stimulus"

// Import Turbo
import "@hotwired/turbo-rails"

// Import Stimulus controller registration helper
import { registerControllers } from "stimulus-vite-helpers"

// Import Vue
import { createApp, nextTick } from 'vue';
import Dropdown from '../../frontend/components/Dropdown.vue';
import UserDropdown from '../../frontend/components/UserDropdown.vue';

// Import Chartkick
import VueChartkick from 'vue-chartkick';
import 'chartkick/chart.js';

// Initialize Stimulus application
const application = Application.start()

// Load and register all controllers
const controllers = import.meta.glob('../controllers/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// Import Tailwind CSS Stimulus components
import { Dropdown as TailwindDropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"

// Register Tailwind CSS Stimulus components
application.register('dropdown', TailwindDropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

// Import and register Flatpickr
// import Flatpickr from 'stimulus-flatpickr'
// application.register('flatpickr', Flatpickr)

// Import and register PageSectionsController
import PageSectionsController from '../controllers/page_sections_controller'
application.register('page-sections', PageSectionsController)

// Vue component mounting logic
function mountComponent(Component, selector, propsMapper) {
  const elements = document.querySelectorAll(`${selector}:not(.vue-mounted)`);
  console.log(`Found ${elements.length} elements for selector: ${selector}`);
  elements.forEach((el) => {
    if (!el.classList.contains('vue-mounted')) {
      const props = propsMapper(el);
      console.log('Mounting component with props:', props);
      createApp(Component, props).mount(el);
      el.classList.add('vue-mounted');
      console.log('Component mounted on element:', el);
    }
  });
}

function mountDropdowns() {
  const propsMapper = (el) => ({
    postId: el.dataset.postId,
    previewUrl: el.dataset.previewUrl,
    editUrl: el.dataset.editUrl || 'default-edit-url',
  });
  console.log('Mounting Dropdown components');
  mountComponent(Dropdown, '.dropdown', propsMapper);
}

function mountUserDropdowns() {
  const propsMapper = (el) => ({
    userId: el.dataset.userId,
    publicProfileUrl: el.dataset.publicProfileUrl,
    editUrl: el.dataset.editUrl || 'default-edit-url',
  });
  console.log('Mounting UserDropdown components');
  mountComponent(UserDropdown, '.user-dropdown', propsMapper);
}

function mountComponents() {
  console.log('Mounting components');
  mountDropdowns();
  mountUserDropdowns();
}

// MutationObserver setup
let observer = null;

function startObserving() {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
  observer = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        console.log('DOM mutation detected');
        mountComponents();
      }
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

function stopObserving() {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
}

// Initial component mounting
console.log('Initial mount');
nextTick(() => {
  mountComponents();
});

// Start observing for new elements
startObserving();

// Export the application for use in other files if needed
export { application }